import React, { Component } from "react";

import "./App.css";
import styled from "styled-components";

import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Page from "./components/Page";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

const AppContainer = styled.div`
  background-color: ${(props) => (props.isDarkMode ? "var(--myrtle-green)" : "var(--azure-white)")};
  color: ${(props) => (props.isDarkMode ? "var(--azure-white)" : "var(--myrtle-green)")};
  transition: background-color 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
`;

const ContentContainer = styled.div`
  width: 65%;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: true,
      screenWidth: window.innerWidth,
    };
    window.addEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  handleDarkModeClick = () => {
    this.setState((state) => ({ isDarkMode: !state.isDarkMode }));
  };

  render() {
    const { isDarkMode, screenWidth } = this.state;
    const isMobile = screenWidth < 600;
    return (
      <AppContainer isDarkMode={isDarkMode}>
        <Header isDarkMode={isDarkMode} onDarkModeClick={this.handleDarkModeClick} />
        <ContentContainer>
          <Intro />
          <Page id="aboutme" title="about me">
            <AboutMe />
          </Page>
          <Page id="skills" title="skills">
            <Skills />
          </Page>
          <Page id="experience" title="experience">
            <Experience isMobile={isMobile} />
          </Page>
          <Page id="projects" title="projects">
            <Projects />
          </Page>
          <Footer />
        </ContentContainer>
      </AppContainer>
    );
  }
}

export default App;
