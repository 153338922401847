import React, { useState } from "react";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import styled from "styled-components";

const ExperienceContainer = styled.div`
  width: 100%;
  min-height: 26vh;
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ExperienceItem = styled.div`
  width: 85%;
  margin-left: 16px;
  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
  }
`;

const ExperienceTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const ExperienceSubtitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
`;

const StyledTabs = styled(Tabs)`
  width: 15%;
  border-right: 1px solid var(--dark-keppel);
  &:focus {
    color: var(--plain-white);
  }
  @media (max-width: 600px) {
    border-right: none;
    border-bottom: 1px solid var(--dark-keppel);
    width: 100%;
  }
`;

const getSelectedTabContent = (selectedTab, experience) => {
  return (
    <ExperienceItem key={experience[selectedTab].duration}>
      <div>
        <ExperienceTitle>{experience[selectedTab].companyName}</ExperienceTitle>
      </div>
      <div>
        <span style={{ float: "left" }}>
          <ExperienceSubtitle>{experience[selectedTab].location}</ExperienceSubtitle>
        </span>
        <span style={{ float: "right" }}>
          <ExperienceSubtitle>{experience[selectedTab].duration}</ExperienceSubtitle>
        </span>
      </div>
      <div style={{ clear: "both" }}>
        {experience[selectedTab].description.map((descriptionItem) => (
          <p key={descriptionItem}>{descriptionItem}</p>
        ))}
      </div>
    </ExperienceItem>
  );
};

const Experience = ({ isMobile }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const experience = [
    {
      jobTitle: "Software Engineer Intern",
      companyName: "Microsoft",
      duration: "Sept 2021 - Dec 2021",
      location: "Vancouver, BC",
      description: [
        "In September, I will be joining Microsoft for my final co-op work term as a member of the Fluid Framework and Experiences team. This team exists within the Office Experience Organization and I am thrilled to be given the opportunity to work on a product that is used by so many people around the world daily.",
      ],
    },
    {
      jobTitle: "Software Engineer Intern",
      companyName: "Intuit",
      duration: "May 2021 - Aug 2021",
      location: "Toronto, ON",
      description: [
        "In May 2021, I returned to Intuit as a member of the Data In Team for TurboTax Online. This gave me a unique opportunity to both gain experience in back-end services and DevOps, while also utilizing my front-end experience from my previous co-op at Intuit.",
        "In this role, I was responsible for optimizing the way that users could enter their tax data. This meant allowing users to enter their tax data using PDFs, images, CSVs, and manually. Additionally, I made improvements to the CI/CD pipeline, allowing for easier deployment and automatic releasing with a changelog generated from merged Pull Requests.",
      ],
    },
    {
      jobTitle: "Software Engineer Intern",
      companyName: "Intuit",
      duration: "Jan 2020 - Aug 2020",
      location: "Edmonton, AB",
      description: [
        "At Intuit, I was given the opportunity to work on QuickBooks Online, an accounting software. I was extremely excited about being able to work directly on a product used by over six million people to help solve the accounting problems of their small businesses.",
        "In this role, I worked on the QuickBooks Online Dashboard, the landing page for all users upon logging in to the service. This work included working on interactive and accessible widgets to be displayed on the dashboard, bug fixes and optimizations to ensure that logging in was as fast as possible, as well as work to the sign- up interview for new users.",
      ],
    },
    {
      jobTitle: "Software Engineer Intern",
      companyName: "Rational Robotics",
      duration: "Apr 2019 - Aug 2019",
      location: "Edmonton, AB",
      description: [
        "Over the Summer of 2019, I was able to work for an Edmonton-based startup company, Rational Robotics. Rational Robotics aimed to build custom robotic solutions for industrial applications. This work was exciting to me because I was able to write software solutions to problems based in the real-world.",
        "At Rational Robotics I used OpenCV computer vision techniques to dynamically generate toolpaths for industrial robotic applications. I also wrote embedded software controlled by microcontrollers to control various servos, stepper motors, and other electronic components, all of which were controlled by a ROS server.",
      ],
    },
  ];
  return (
    <ExperienceContainer>
      <StyledTabs
        TabIndicatorProps={{ style: { backgroundColor: "var(--keppel)" } }}
        value={selectedTab}
        orientation={isMobile ? "horizontal" : "vertical"}
        onChange={(_, newSelectedTab) => setSelectedTab(newSelectedTab)}
      >
        {experience.map((experienceItem, index) => (
          <Tab key={experienceItem.duration} label={`0${index + 1}.`} />
        ))}
      </StyledTabs>
      {getSelectedTabContent(selectedTab, experience)}
    </ExperienceContainer>
  );
};

Experience.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Experience;
