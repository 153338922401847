import React from "react";

import styled from "styled-components";

const SkillsGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const Skills = () => {
  return (
    <SkillsGrid>
      <li>{"CSS"}</li>
      <li>{"C++"}</li>
      <li>{"Flutter"}</li>
      <li>{"HTML"}</li>
      <li>{"Java"}</li>
      <li>{"Javascript"}</li>
      <li>{"Python"}</li>
      <li>{"React"}</li>
      <li>{"Redux"}</li>
      <li>{"Rust"}</li>
      <li>{"SQL"}</li>
      <li>{"Typescript"}</li>
    </SkillsGrid>
  );
};

export default Skills;
