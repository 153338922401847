import React from "react";

import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import styled from "styled-components";

const FooterSection = styled.div`
  padding: 20px 0;
  font-size: 16px;
  text-align: center;
  & > div {
    padding: 16px 0 0 0;
  }
`;

const LinkSection = styled.a`
  margin: 16px 16px;
  color: inherit;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    color: var(--dark-keppel);
  }
`;

const Footer = () => {
  return (
    <FooterSection>
      <div>{"Built by Nayan Prakash."}</div>
      <div>
        <LinkSection href="https://github.com/nayancprakash">
          <GitHubIcon />
        </LinkSection>
        <LinkSection href="https://www.linkedin.com/in/nayanprakash/">
          <LinkedInIcon />
        </LinkSection>
        <LinkSection href="mailto:nayan@ualberta.ca">
          <EmailRoundedIcon />
        </LinkSection>
      </div>
    </FooterSection>
  );
};

export default Footer;
