import React from "react";

import styled from "styled-components";

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const AboutMeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  margin: 0 16px;
  max-width: 800px;
`;

const AboutMeImage = styled.img`
  position: relative;
  margin: 10px;
  max-width: 300px;
  max-height: 300px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.46);
  &:before {
    content: "";
    float: left;
    padding-top: 100%;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const AboutMe = () => {
  return (
    <AboutMeContainer>
      <AboutMeDescriptionContainer>
        <p>
          {"My name is "}
          <BoldSpan>{"Nayan Prakash"}</BoldSpan>
          {" (pronounced like Ryan). I am a fifth-year "}
          <BoldSpan>{"Computer Software Engineering"}</BoldSpan>
          {" co-op student at the "}
          <BoldSpan>{"University of Alberta"}</BoldSpan>
          {
            ". During my degree, I have studied topics including data structures and algorithms, object oriented programming, operating systems, and reinforcement learning. Currently, I am working as an intern at Microsoft."
          }
        </p>
        <p>
          {
            "When I'm not in front of the computer I like to work on projects, compete in hackathons and CTFs, and learn new technologies. Outside of programming, I like to travel, practice photography, watch movies, cook, and try new foods."
          }
        </p>
      </AboutMeDescriptionContainer>
      <AboutMeImage src="NayanProfilePicture1.jpg" />
    </AboutMeContainer>
  );
};

export default AboutMe;
