import React, { Component } from "react";

import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TextLoop from "react-text-loop";
import Typist from "react-typist";
import styled from "styled-components";
import "react-typist/dist/Typist.css";

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 55px;
  min-height: 90vh;
`;

const IntroTitle = styled.div`
  margin-top: 40vh;
  font-size: 86px;
  @media (max-width: 600px) {
    margin-top: 10vh;
  }
`;

const IntroTitleHighlight = styled.span`
  color: var(--keppel);
  font-weight: bold;
`;

const IntroSubtitle = styled.div`
  font-size: 55px;
`;

const IntroDescription = styled.div`
  font-size: 20px;
`;

const StyledTypist = styled(Typist)`
  .Cursor--blinking {
    color: var(--keppel);
  }
`;

const LinksTray = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  & > a,
  a:focus,
  a:active {
    margin: 0 10px;
    transition: 0.5s;
    color: inherit;
  }
  & > a:hover {
    cursor: pointer;
    color: var(--dark-keppel);
  }
`;

class Intro extends Component {
  render() {
    return (
      <IntroContainer>
        <IntroTitle>
          <StyledTypist avgTypingDelay={120}>
            {"hi, I'm "}
            <IntroTitleHighlight>Nayan</IntroTitleHighlight>
            {"."}
          </StyledTypist>
        </IntroTitle>
        <IntroSubtitle>{"I build things."}</IntroSubtitle>
        <IntroDescription>
          <div>{"I'm a fifth-year Software Engineering Student at the University of Alberta."}</div>
          <div>
            {"I'm interested in "}
            <TextLoop interval={1500}>
              <span>full-stack development.</span>
              <span>machine learning.</span>
              <span>solving problems.</span>
              <span>learning new things.</span>
            </TextLoop>
          </div>
        </IntroDescription>
        <LinksTray>
          <a href="https://github.com/nayancprakash">
            <GitHubIcon />
          </a>
          <a href="https://www.linkedin.com/in/nayanprakash/">
            <LinkedInIcon />
          </a>
          <a href="mailto:nayan@ualberta.ca">
            <EmailRoundedIcon />
          </a>
        </LinksTray>
      </IntroContainer>
    );
  }
}

export default Intro;
