import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const PageContainer = styled.div`
  width: 100%;
  padding-top: 10vh;
  margin-bottom: 30px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 28px;
  max-width: 100vw;
  font-size: 46px;
  font-weight: bold;
  &:after {
    flex-grow: 1;
    content: "";
    display: block;
    margin-top: 44px;
    height: 1px;
    margin-left: 20px;
    background-color: var(--dark-keppel);
  }
`;

const Page = ({ id, title, children }) => {
  return (
    <PageContainer id={id}>
      <PageHeader>{`${title}`}</PageHeader>
      {children}
    </PageContainer>
  );
};

Page.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Page;
