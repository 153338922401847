import React from "react";

import GitHubIcon from "@material-ui/icons/GitHub";
import PropTypes from "prop-types";
import styled from "styled-components";

const ProjectItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  width: 375px;
  // min-height: 20vh;
  border-radius: 16px;
  background-color: var(--azure-white);
  transition: 0.5s;
  box-shadow: 6px 13px 23px -5px rgba(0, 0, 0, 0.45);
  &:hover {
    background-color: var(--plain-white);
    transform: translate(-16px, -16px);
    box-shadow: 22px 29px 23px 5px rgba(0, 0, 0, 0.45);
  }
`;

const ProjectItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px;
  height: 100%;
  color: var(--myrtle-green);
`;

const ProjectItemTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StyledLinkAnchor = styled.a`
  color: var(--myrtle-green);
  transition: 0.5s;
  &:focus,
  &:active {
    color: inherit;
  }
  &:hover {
    cursor: pointer;
    color: var(--dark-keppel);
  }
`;

const ProjectItemSubtitle = styled.div`
  clear: both
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 14px;
`;
const ProjectItem = ({ data }) => {
  const { name, date, link, description, utilized } = data;
  return (
    <ProjectItemContainer>
      <ProjectItemContent>
        <div>
          <span style={{ float: "left" }}>
            <ProjectItemTitle>{name}</ProjectItemTitle>
          </span>
          {link && (
            <span style={{ float: "right" }}>
              <StyledLinkAnchor href={link}>
                <GitHubIcon />
              </StyledLinkAnchor>
            </span>
          )}
        </div>
        <div style={{ clear: "both" }}>
          <ProjectItemSubtitle>{date}</ProjectItemSubtitle>
        </div>
        <p>{description}</p>
        <p style={{ marginTop: "auto" }}>
          <span style={{ textDecoration: "underline" }}>{"Utilized:"}</span>
          {` ${utilized}`}
        </p>
      </ProjectItemContent>
    </ProjectItemContainer>
  );
};

ProjectItem.propTypes = {
  data: PropTypes.object.isRequired,
};

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  // grid-gap: 16px;
`;

const Projects = () => {
  const projectsList = [
    {
      name: "magpieCTF",
      date: "Feb 2021",
      description:
        "My first CTF where I got to learn about web exploitation, binary exploitation, reverse engineering, and much more.",
      utilized: "C++, Python.",
    },
    {
      name: "Bookmark",
      date: "Sept 2020 - Dec 2020",
      description: "A native Java Android mobile app that aims to pair book owners with book borrowers.",
      // description:
      //   "Bookmark is a native Java Android mobile app that served as the final project for my CMPUT 301 university course. This app aims to pair owners of books with other users who may want to borrow that book. My role while working on this app was to handle the pairing of book owners and borrowers. This work included managing the various statuses of books and allowing users to view and set a meeting location for the exchange of the borrowed book.",
      link: "https://github.com/CMPUT301F20T01/Bookmark",
      utilized: "Android Studio, Firebase, Java.",
    },
    {
      name: "Aspire",
      date: "Apr 2020 - Aug 2020",
      description: "A cross-platform mobile app to pair industry mentors with student mentees.",
      // description:
      //   "Aspire is a cross-platform mobile app that aims to pair industry mentors with student mentees. The goal of this app was to serve as the bridge between industry and upcoming students. I worked on this app alongside a small team in which daily standup meetings were held and rapid development was made. I implemented the entirety of the app's messaging functionality, allowing any mentors and mentees which have been paired to share text, image, and file messages.",
      link: "https://github.com/AspireUni/Aspire",
      utilized: "Firebase, Firestore, Flutter, Redux.",
    },
    {
      name: "Census",
      date: "Sept 2018 — Apr 2019",
      description: "A cross-platform location-based mobile app to poll users in a nearby area.",
      // description:
      //   "Census is a cross-platform mobile location-based polling app. This app allows users to post questions and poll other users in the nearby area. This app was developed with a small team and allowed me to learn about app development and apply the concepts from my university courses and put them in to practice. While working on this app, I worked on developing a cohesive API that allowed the front-end application to communicate with a back-end database.",
      utilized: "Node, React Native, SQL.",
    },
    {
      name: "Canadian Engineering Competition",
      date: "Mar 2019",
      description: "A competition in which a block-box API had to be utilized to efficiently solve a problem.",
      // description:
      //   "My team and I progressed to the Canadian Engineering Competition after placing 2nd at the Western Engineering Competition earlier in the year. For this round of the competition we were tasked with developing the algorithms necessary to interact with a black-box API and solve a complex problem as efficiently as possible. In this 8 hour competition, our team quickly discussed an appropriate solution and immediately began coding to solve the problem.",
      link: "https://github.com/kylehennig/cec2019",
      utilized: "Node.js, RESTful APIs.",
    },
    {
      name: "Western Engineering Competition",
      date: "Jan 2019",
      description: "A competition in which I had to write a bot to efficiently solve a NP-complete problem.",
      // description:
      //   "I was given the opportunity to compete in the Programming Division of the Western Engineering Competition alongside 3 other engineering students. For this 8 hour competition we were given the task of creating a game with a fully-fledged front-end and back-end, as well as a bot to solve the game. For this competition, I took on the role of creating the back-end game logic as well as the bot used to solve the complex, NP-complete problem with a high probability of success.",
      link: "https://github.com/kylehennig/wec2019",
      utilized: "PixiJS, Tornado, WebSockets.",
    },
    {
      name: "A* Battles",
      date: "Jan 2019",
      description:
        "A game where you compete to use walls to prevent an enemy's seeker from pathseeking to a shared goal.",
      // description:
      //   "A* Battles is a game that was created as a project for my CMPUT 274 university course. A* Battles is a multiplayer game that uses the A* pathfinding algorithm to pit two player against each other. Players control a row of walls that they must use to block the opponent's A* pathfinding seeker from reaching the flag. This game comes complete with procedural map generation, a map editor, local multiplayer, and online multiplayer.",
      link: "https://github.com/mitchadam/astar-battles",
      utilized: "Python, Tornado, WebSockets.",
    },
  ];

  return (
    <ProjectsContainer>
      {projectsList.map((project) => (
        <ProjectItem key={project.name} data={project} />
      ))}
    </ProjectsContainer>
  );
};

export default Projects;
