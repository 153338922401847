import React from "react";

import Brightness4Icon from "@material-ui/icons/Brightness4";
import PropTypes from "prop-types";
import styled from "styled-components";

const HeaderContainer = styled.div`
  background-color: inherit;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 10vh;
  width: 100%;
  z-index: 1;
  padding-right: 5vw;
`;

const HeaderButton = styled.div`
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: inherit;
  transition: 0.5s, width 0.5s;
  &:hover {
    cursor: pointer;
    color: var(--dark-keppel);
  }
`;

const Header = ({ onDarkModeClick }) => {
  return (
    <HeaderContainer>
      <HeaderButton onClick={() => (window.location.href = "/#aboutme")}>{"about me"}</HeaderButton>
      <HeaderButton onClick={() => (window.location.href = "/#skills")}>{"skills"}</HeaderButton>
      <HeaderButton onClick={() => (window.location.href = "/#experience")}>{"experience"}</HeaderButton>
      <HeaderButton onClick={() => (window.location.href = "/#projects")}>{"projects"}</HeaderButton>
      <HeaderButton onClick={() => onDarkModeClick()}>
        <Brightness4Icon style={{ display: "block" }} />
      </HeaderButton>
    </HeaderContainer>
  );
};

Header.propTypes = {
  onDarkModeClick: PropTypes.func.isRequired,
};

export default Header;
